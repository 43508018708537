import Vue from 'vue'
import Router from 'vue-router'
import { publicRoute, protectedRoute } from './config'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '@/store/index.js'

const routes = publicRoute.concat(protectedRoute)

Vue.use(Router)
const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  routes: routes,
})
// router gards
router.beforeEach((to, from, next) => {
  NProgress.start()
  //auth route is authenticated
  if (
    (store.state.auth.login.token && store.state.auth.login.expire > new Date().getTime()) ||
    to.matched.every((page) => {
      const matchesPath = (path, config) => {
        for (const i in config) {
          console.log(path + ' : ' + config[i].path)
          if (
            config[i].path === path ||
            config[i].path + '/' === path ||
            config[i].redirect === path ||
            config[i].redirect + '/' === path
          ) {
            return true
          } else if (config[i].children) {
            if (matchesPath(path, config[i].children)) {
              return true
            }
          }
        }
        return false
      }
      return matchesPath(page.path === '' ? '/' : page.path, publicRoute)
    })
  ) {
    next()
  } else {
    next({ path: '/auth/login', query: { backuri: to.fullPath } })
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
