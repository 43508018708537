<template>
  <v-app style="background-color: #eee">
    <v-main>
      <router-view :key="$route.path"></router-view>
      <!-- App Footer -->
      <v-footer height="auto" class="pa-3 app--footer">
        <v-row justify="center" no-gutters>
          <span>&copy; 株式会社TKF {{ new Date().getFullYear() }}</span>
        </v-row>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({}),
  methods: {},
}
</script>
<style lang="sass" scoped>
.layout-auth
  height: 50%
  width: 100%
  position: absolute
  top: 0
  left: 0
  content: ""
  z-index: 0
</style>
