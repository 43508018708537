<template>
  <div>
    <div class="text-center">
      <img src="/static/icon/logo.png" alt="かみしほろマルシェ" style="max-width: 95%; width: 500px" />
    </div>
    <div class="text-h5 text-center mt-4 mb-5" style="color: #121a44">ポイントバックキャンペーン</div>
    <v-main>
      <!-- Page Wrapper -->
      <div class="page_wrapper"><router-view /></div>
      <!-- App Footer -->
      <v-footer height="auto" class="pa-3 app--footer">
        <v-row justify="center" no-gutters>
          <span>&copy; 株式会社TKF {{ new Date().getFullYear() }}</span>
        </v-row>
      </v-footer>
    </v-main>
    <!-- Go to top -->
    <app-fab />
  </div>
</template>

<script>
export default {
  name: 'LayoutDefault',
}
</script>
