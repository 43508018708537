import { LayoutAuth, LayoutDefault } from '@/components/layouts'

export const publicRoute = [
  {
    path: '*',
    component: () => import('@/views/error/NotFound.vue'),
  },
  {
    path: '/',
    component: LayoutDefault,
    meta: { title: 'top_index' },
    redirect: '/campaign',
    children: [
      {
        path: '/campaign',
        name: 'CampaignIndex',
        meta: {
          title: 'campaign_index',
          metaTags: {
            name: 'robots',
            content: 'noindex',
          },
        },
        component: () => import('@/views/campaign/Index.vue'),
      },
    ],
  },

  {
    path: '/auth',
    component: LayoutAuth,
    meta: { title: 'auth_login' },
    redirect: '/auth/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'Login',
        meta: { title: 'auth_login' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue'),
      },
      {
        path: 'logout',
        name: 'Logout',
        meta: { title: 'auth_logout' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Logout.vue'),
      },
    ],
  },

  {
    path: '/403',
    name: 'Forbidden',
    meta: {
      title: 'error_403',
      hiddenInMenu: true,
    },
    component: () => import('@/views/error/Deny.vue'),
  },

  {
    path: '/404',
    name: '404',
    meta: {
      title: 'error_404',
    },
    component: () => import('@/views/error/NotFound.vue'),
  },

  {
    path: '/500',
    name: '500',
    meta: {
      title: 'error_500',
    },
    component: () => import('@/views/error/Error.vue'),
  },
]

export const protectedRoute = [
  {
    path: '/top',
    component: LayoutDefault,
    redirect: '/campaign',
    meta: {
      title: 'campaign_index',
    },
    children: [
      {
        path: '/application',
        name: 'ApplicationIndex',
        meta: {
          title: 'application_index',
          metaTags: {
            name: 'robots',
            content: 'noindex',
          },
        },
        component: () => import('@/views/application/Index.vue'),
      },
    ],
  },
]
